import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const ChristmasCardPopupWrapper = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 5;
  top: 0%;
  left: 0%;
`

const ChristmasContainer = styled.div`
  width: 100%;
  max-width: 480px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 20px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 9;
  box-shadow: 0 0 15px 2px #cecece80;
  transition: visibility 0s, opacity 0.5s linear;
  overflow: auto;
`

const CloseButton = styled.div`
  color: #262626;
  font-size: 40px;
  position: absolute;
  line-height: 1;
  right: 0px;
  top: 0px;
  cursor: pointer;
  z-index: 1;

  :hover {
    color: #262626;
  }
`

const Title = styled.div`
  font-size: 32px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
`

const GiftSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px 20px;
`

const GiftWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`

const GiftImage = styled.img`
  width: 100%;
  max-width: ${({ order }) =>
    order == 0
      ? "90px"
      : order == 1
      ? "110px"
      : order == 2
      ? "130px"
      : order == 3
      ? "150px"
      : order == 4
      ? "175px"
      : "200px"};
  margin: 0px;
  object-fit: contain;
`

const GiftText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;

  strong {
    font-weight: bold;
  }
`

const BottomText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  font-family: "Gotham Book";
  font-weight: 500;
  max-width: 360px;
  text-align: center;

  strong {
    font-weight: bold;
  }
`

const ChristmasCardPopup = ({ intl, data, show, hide }) => {
  return (
    data && (
      <ChristmasCardPopupWrapper show={show}>
        <ChristmasContainer>
          <CloseButton onClick={() => hide(false)}>×</CloseButton>
          {data.title && (
            <Title
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
          )}
          {data.list && (
            <GiftSection>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <GiftWrapper key={index.toString()}>
                      {item.image && (
                        <GiftImage
                          className="lazyload"
                          data-src={item.image}
                          alt={item.image_alt ? item.image_alt : ""}
                          order={index}
                        />
                      )}
                      {item.text && (
                        <GiftText
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                        />
                      )}
                    </GiftWrapper>
                  )
                )
              })}
            </GiftSection>
          )}
          {data.text && (
            <BottomText
              dangerouslySetInnerHTML={{
                __html: data.text,
              }}
            />
          )}
        </ChristmasContainer>
      </ChristmasCardPopupWrapper>
    )
  )
}

export default injectIntl(ChristmasCardPopup)
